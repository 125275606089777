/*!
 * SpreadNShare 3.0 (http://www.virtualys.com)
 * Copyright 2008-2015 Virtualys / IFREMER
 */
.c_menu.l_core_singlelevel {
  padding: 3px 0;
}
.c_menu.l_core_singlelevel .horizontal a:hover {
  color: white;
}
.c_menu.l_core_singlelevel .horizontal .frame {
  height: 40px;
}
.c_menu.l_core_singlelevel .horizontal .frame ul {
  margin: 0 auto;
}
.c_menu.l_core_singlelevel .horizontal li {
  text-transform: uppercase;
  line-height: 32px;
}
.c_menu.l_core_singlelevel .horizontal li > a {
  padding: 4px 11px 0 11px;
  border-bottom: 4px solid transparent;
  transition: border-color 0.25s ease;
}
.c_menu.l_core_singlelevel .horizontal li.active > a,
.c_menu.l_core_singlelevel .horizontal li.current > a,
.c_menu.l_core_singlelevel .horizontal li:hover > a {
  color: #1b1b1b;
  border-bottom-color: #1b1b1b;
}
.c_menu.l_core_singlelevel .horizontal .control {
  background: transparent;
}
.c_menu.l_core_singlelevel .horizontal .control:hover {
  background: #ff6d00;
  color: #424242;
}
.c_menu.l_core_singlelevel .horizontal .control.prev:hover span {
  border: none;
}
.c_menu.l_core_singlelevel .horizontal .control.next:hover span {
  border: none;
}
.c_menu.l_core_singlelevel .vertical ul {
  margin: 0;
  padding: 0 0 0 10px;
  list-style-type: none;
}
#header .c_menu.l_core_singlelevel {
  transition: margin 0.2s;
}
#header .c_menu.l_core_singlelevel .frame {
  height: 24px;
}
#header .c_menu.l_core_singlelevel .horizontal li {
  line-height: 24px;
}
#header .c_menu.l_core_singlelevel .horizontal li > a {
  padding-top: 0;
  border-bottom: none;
}
#header.affix-top ul:not(.dropdown-menu) a {
  color: white;
}
#header.affix-top ul:not(.dropdown-menu) a:hover,
#header.affix-top ul:not(.dropdown-menu) a:focus {
  color: #f6aa0a;
}
#header.affix .c_menu.l_core_singlelevel {
  margin-top: 28px;
}
#header.affix .c_menu.l_core_singlelevel .horizontal a:hover,
#header.affix .c_menu.l_core_singlelevel .horizontal a:focus {
  color: black;
}
/*# sourceMappingURL=cpnt_snshtml5_menu.singlelevel-theme.css.map */